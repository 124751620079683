.ag-cell {
  overflow: visible;
}
.ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
  z-index: 0;
}
.ag-row-focus {
  z-index: 999;
}
.ag-cell-focus {
  outline: none !important;
  border: none !important;
}
.center-header .ag-header-cell-label {
  justify-content: center !important;
}
